import { createContext, useCallback, useContext, useEffect, useState } from "react";
import {
   ConsultiveGroup,
   IAffairs,
   IGovernanceBody,
   ILaboralExperience,
   IProfesionalStudies,
   ITags,
   IUserDetails,
} from "../../types/governance.types";
import { UserContext } from "../userContext";
import {
   getAffairsByCompany,
   getConsultiveGroupById,
   getEventsByCompany,
   getGoberningBodiesByCompany,
   getManyCalendarsByIds,
   getManyGovernanceByManyCompanies,
   getNotesByUser,
   getPersonalSpaceByUserId,
   getTagById,
   getTagsByUser,
   updateEventById,
   updateNoteById,
   updateTask,
} from "../../lib/gobCorpBEClient";
import dayjs from "dayjs";
import { GetGroupById, getManyCompaniesById, getSavedInsights } from "../../lib/usersBEClient";
import useGetInsights from "../../hooks/gob-corp/useGetInsights";
import { useParams } from "react-router-dom";
import _ from "lodash";
interface IGovernanceContext {
   selectedGovernance: IGovernanceBody;
   setSelectedGovernance: Function;
   selectedSection: string;
   setSelectedSection: Function;
   gobernanceBody: IGovernanceBody[];
   fetchGovernaceBody: () => void;
   getUserDetailsData: () => void;
   isLoading: boolean;
   userDetailsLoading: boolean;
   setUserDetailsLoading: Function;
   setUserDetails: Function;
   userDetails: IUserDetails;
   personalCalendar: any;
   specificCalendar: any;
   getSpecificCalendar: Function;
   allCalendars: any;
   refetch: boolean;
   setRefetch: Function;
   tagsFilter: string[];
   setTagsFilterNotes: Function;
   tagsFilterNotes: string[];
   textToFilter: string;
   setTextToFilter: Function;
   setTagsFilter: Function;
   startDate: dayjs.Dayjs;
   setstartDate: Function;
   finishDate: dayjs.Dayjs;
   setfinishDate: Function;
   confirmModal: boolean;
   setConfirmModal: Function;
   refetchTasks: boolean;
   setRefetchTasks: Function;
   refetchAdvices: boolean;
   setRefetchAdvices: Function;
   upDownDates: boolean;
   setUpDownDates: Function;
   refetchComments: boolean;
   setRefetchComments: Function;
   setSpecificCalendar: Function;
   personalSpace: any;
   companySelected: string;
   setCompanySelected: Function;
   fileToDelete: any;
   setFileToDelete: Function;
   laboralExperience: ILaboralExperience[];
   setLaboralExperience: Function;
   professionalStudies: IProfesionalStudies[];
   setProfessionalStudies: Function;
   postgraduate: IProfesionalStudies[];
   setPostgraduate: Function;
   certificates: IProfesionalStudies[];
   setCertificates: Function;
   isUserVerified: boolean;
   setIsUserVerified: Function;
   documentSelected: any;
   setDocumentSelected: Function;
   hideSecondTopMenu: boolean;
   setHideSecondTopMenu: Function;
   resourceAccessGob: any[];
   setResourceAccessGob: Function;
   selectedId: any[];
   affairsByCompany: IAffairs[];
   setSelectedId: Function;
   verifyAndUpdateTags: Function;
   notesTags: ITags[];
   setNoteTags: Function;
   eventTags: ITags[];
   savedInsights: any[];
   consultiveGroup: ConsultiveGroup;
   consultiveGroupCompanies: any[];
   isLoadingConsultiveGroup: boolean;
   openGovernanceSelection: { open: boolean; title: string };
   setOpenGovernanceSelection: Function;
   insights: any[];
   guides: any[];
   updateSavedInsights: Function;
   removeFromSavedInsights: Function;
}

export const GovernanceContext = createContext<IGovernanceContext>({
   gobernanceBody: null,
   selectedGovernance: {
      _id: "0",
      title: "PANEL DE USUARIO",
      tags: [],
   },
   setSelectedGovernance: () => {},
   selectedSection: "Dashboard",
   setSelectedSection: () => {},
   fetchGovernaceBody: () => {},
   getUserDetailsData: () => {},
   isLoading: false,
   userDetailsLoading: false,
   setUserDetailsLoading: () => {},
   setUserDetails: () => {},
   userDetails: {
      _id: "",
      gender: "",
      address: "",
      birthLocation: "",
      dob: "",
   },
   refetch: false,
   setRefetch: () => {},
   personalCalendar: null,
   specificCalendar: null,
   allCalendars: null,
   getSpecificCalendar: () => {},
   tagsFilter: [],
   setTagsFilterNotes: () => {},
   tagsFilterNotes: [],
   textToFilter: "",
   setTextToFilter: () => {},
   setTagsFilter: () => {},
   startDate: null,
   setstartDate: () => {},
   finishDate: null,
   setfinishDate: () => {},
   confirmModal: false,
   setConfirmModal: () => {},
   refetchTasks: false,
   setRefetchTasks: () => {},
   refetchAdvices: false,
   setRefetchAdvices: () => {},
   upDownDates: false,
   setUpDownDates: () => {},
   refetchComments: false,
   setRefetchComments: () => {},
   personalSpace: null,
   companySelected: "",
   setCompanySelected: () => {},
   setSpecificCalendar: () => {},
   fileToDelete: null,
   setFileToDelete: () => {},
   laboralExperience: [],
   setLaboralExperience: () => {},
   professionalStudies: [],
   setProfessionalStudies: () => {},
   postgraduate: [],
   setPostgraduate: () => {},
   certificates: [],
   setCertificates: () => {},
   isUserVerified: null,
   setIsUserVerified: () => {},
   documentSelected: null,
   setDocumentSelected: () => {},
   hideSecondTopMenu: true,
   setHideSecondTopMenu: () => {},
   resourceAccessGob: [],
   setResourceAccessGob: () => {},
   selectedId: [],
   affairsByCompany: [],
   setSelectedId: () => {},
   verifyAndUpdateTags: () => {},
   notesTags: [],
   setNoteTags: () => {},
   eventTags: [],
   savedInsights: [],
   consultiveGroup: null,
   consultiveGroupCompanies: [],
   isLoadingConsultiveGroup: false,
   openGovernanceSelection: { open: false, title: "" },
   setOpenGovernanceSelection: () => {},
   insights: [],
   guides: [],
   updateSavedInsights: () => {}, // Agregar aquí
   removeFromSavedInsights: () => {}, // Agregar aquí
});

export const GovernanceProvider = ({ children }) => {
   const [selectedGovernance, setSelectedGovernance] = useState({
      _id: "0",
      title: "PANEL DE USUARIO",
      tags: [],
      users: [],
   });
   const [gobernanceBody, setGobernanceBody] = useState<IGovernanceBody[] | any>([]);
   const [selectedSection, setSelectedSection] = useState<string>("Dashboard");
   const [personalCalendar, setPersonalCalendar] = useState(null);
   const [personalSpace, setPersonalSpace] = useState(null);
   const [specificCalendar, setSpecificCalendar] = useState(null);
   const [selectedId, setSelectedId] = useState([]);
   const [textToFilter, setTextToFilter] = useState("");
   const [allCalendars, setAllCalendars] = useState([]);
   const [tagsFilter, setTagsFilter] = useState([]);
   const [tagsFilterNotes, setTagsFilterNotes] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const { user, roles, resources, companies, groups } = useContext(UserContext);
   const [userDetailsLoading, setUserDetailsLoading] = useState(false);
   const [userDetails, setUserDetails] = useState<IUserDetails>();
   const [refetch, setRefetch] = useState(false);
   const [refetchTasks, setRefetchTasks] = useState(false);
   const [refetchAdvices, setRefetchAdvices] = useState(false);
   const [startDate, setstartDate] = useState(null);
   const [finishDate, setfinishDate] = useState(null);
   const [confirmModal, setConfirmModal] = useState(false);
   const [upDownDates, setUpDownDates] = useState(false);
   const [refetchComments, setRefetchComments] = useState(false);
   const [companySelected, setCompanySelected] = useState("");
   const [fileToDelete, setFileToDelete] = useState(null);
   const [laboralExperience, setLaboralExperience] = useState([]);
   const [professionalStudies, setProfessionalStudies] = useState([]);
   const [postgraduate, setPostgraduate] = useState([]);
   const [certificates, setCertificates] = useState([]);
   const [isUserVerified, setIsUserVerified] = useState<boolean>(null);
   const [documentSelected, setDocumentSelected] = useState(null);
   const [hideSecondTopMenu, setHideSecondTopMenu] = useState(true);
   const [resourceAccessGob, setResourceAccessGob] = useState([]);
   const [affairsByCompany, setAffairsByCompany] = useState([]);
   const [notesTags, setNoteTags] = useState<ITags[]>([]);
   const [eventTags, setEventTags] = useState<ITags[]>([]);
   const [savedInsights, setSavedInsights] = useState<any[]>(null);
   const [consultiveGroup, setConsultiveGroup] = useState(null);
   const [isLoadingConsultiveGroup, setisLoadingConsultiveGroup] = useState(false);
   const [openGovernanceSelection, setOpenGovernanceSelection] = useState({ open: false, title: "" });
   const [consultiveGroupCompanies, setConsultiveGroupCompanies] = useState([]);
   const { insights, guides } = useGetInsights([
      "Buenas prácticas empresariales",
      "Gobierno Corporativo",
      "Beneficiario Controlador",
      "Programa de Cumplimiento",
      "SIROC",
      "REPSE",
      "Canal de Denuncias",
   ]);
   const { companyId } = useParams();

   useEffect(() => {
      const fetchTags = async () => {
         try {
            const [tagsData, eventsData, notesData] = await Promise.all([
               getTagsByUser(userDetails._id),
               getEventsByCompany(companySelected),
               getNotesByUser(user.id, companySelected),
            ]);
            const getUniqueTags = (data, additionalTags = []) => {
               const allTags = [...tagsData, ...additionalTags];
               const tagSet = new Set();
               return allTags.filter((tag) => {
                  if (!tagSet.has(tag._id)) {
                     tagSet.add(tag._id);
                     return true;
                  }
                  return false;
               });
            };

            const tempEventTags = eventsData.flatMap((event) => event.tags.filter((tag) => tag._id));
            const uniqueListEvents = getUniqueTags(eventsData, tempEventTags);
            setEventTags(uniqueListEvents);

            const tempTags = notesData.flatMap((note) => note.tags.filter((tag) => tag._id));
            const uniqueList = getUniqueTags(notesData, tempTags);
            if (!uniqueList.some((t) => t.title === "Compartidas conmigo")) {
               uniqueList.unshift({ _id: "Compartidas conmigo", title: "Compartidas conmigo", color: "#FFF" });
            }
            setNoteTags(uniqueList);
         } catch (error) {
            console.error("Error fetching tags:", error);
         }
      };
      if (userDetails && companySelected) fetchTags();
   }, [userDetails, companySelected]);

   const sortGoverningBodies = (arrayToSort) => {
      const order = ["Asamblea de accionistas", "Consejo de administración", "Comité directivo"]; //SORT GOVERNANCE ORDER
      const mainGB = arrayToSort.filter((body) => order.includes(body.title));
      const supportGB = arrayToSort.filter((body) => !order.includes(body.title));
      mainGB.sort((a, b) => order.indexOf(a.title) - order.indexOf(b.title));
      const committeeIndex = mainGB.findIndex((body) => body.title === "Comité directivo");
      if (committeeIndex !== -1) mainGB.splice(committeeIndex, 0, ...supportGB);
      else mainGB.push(...supportGB);
      return mainGB;
   };

   const fetchGovernaceBody = async () => {
      setIsLoading(true);
      try {
         //await getUserDetailsData();
         if (companySelected) {
            let response = await getGoberningBodiesByCompany(companySelected);

            if (companySelected === consultiveGroup?._id) {
               let companiesGoverningBodiesResponse = await getManyGovernanceByManyCompanies(
                  consultiveGroup.allCompanies
               );
               if (roles.includes("Coordinador de gobierno corporativo") || roles.includes("Usuario de implementación"))
                  response.push(...companiesGoverningBodiesResponse);
               else {
                  const userGovernance = companiesGoverningBodiesResponse.filter((a) =>
                     a.users.some((u) => u.user === user.id)
                  );
                  response.unshift(...userGovernance);
               }
               response = sortGoverningBodies(response);
            }
            response = response.filter((g) => g.title !== "Asamblea de socios" && g.title !== "Consejo de gerentes"); //TEMPORAL UNTIL UPDATE ON GOVERNANCE
            const personalDataResponse = await getPersonalSpaceInfo(response);
            setPersonalSpace(personalDataResponse);

            const governanceBody = [
               {
                  _id: "0",
                  title: "PANEL DE USUARIO",
                  calendar: personalDataResponse?.calendar,
               },
               ...response,
            ];
            setGobernanceBody(governanceBody);
            const affairsList = await getAffairsByCompany(companySelected);
            setAffairsByCompany(affairsList.data);
         }
         setIsLoading(false);
      } catch (error) {
         console.log(error);
      }
   };

   useEffect(() => {
      if (companySelected && isUserVerified && user && roles.length > 0) fetchGovernaceBody();
   }, [companySelected, isUserVerified, refetch, user, roles, consultiveGroup, companyId]);

   //DELETE IN FUTURE
   const verifyAndUpdateTags = async (data, type) => {
      const updatePromises = [];

      let updatefunction;
      switch (type) {
         case "events":
            updatefunction = updateEventById;
            break;
         case "notes":
            updatefunction = updateNoteById;
            break;
         case "tasks":
            updatefunction = updateTask;
            break;
         default:
            console.error("Invalid type provided");
            return;
      }
      if (!updatefunction) {
         console.error("No update function assigned");
         return;
      }
      for (const iterator of data) {
         if (iterator.tags?.some((tag) => typeof tag === "string")) {
            for (let index = 0; index < iterator.tags.length; index++) {
               if (typeof iterator.tags[index] === "string") {
                  const tagResponse = await getTagById(iterator.tags[index]);
                  if (tagResponse) iterator.tags[index] = tagResponse;
                  else {
                     iterator.tags.splice(index, 1);
                     index--;
                  }
               }
            }
            updatePromises.push(updatefunction(iterator._id, { tags: iterator.tags }, "", ""));
         }
      }

      await Promise.all(updatePromises);
   };

   const getPersonalSpaceInfo = async (bodies) => {
      const responsePersonalSpace = await getPersonalSpaceByUserId(user.id);
      const events = await getEventsByCompany(companySelected);
      //VERIFY IF OLD TAGS MODEL
      //await verifyAndUpdateTags(events, "events"); //DELETE IN FUTURE
      setPersonalCalendar(personalCalendar);
      const calendarObject = { _id: responsePersonalSpace?.calendar, event: events, task: [], session: [] };

      const isCoordinadorOrUsuario =
         roles.includes("Coordinador de gobierno corporativo") || roles.includes("Usuario de implementación");
      const filteredBodies = isCoordinadorOrUsuario
         ? bodies
         : bodies.filter((a) => a.users.some((u) => u.user === user.id));
      const calendarsResponse = await getManyCalendarsByIds(filteredBodies.map((body) => body?.calendar));
      setAllCalendars([calendarObject, ...calendarsResponse]);
      return responsePersonalSpace;
   };

   async function getUserDetailsData() {
      // try {
      //    setUserDetailsLoading(true);
      //    const userDetails = await getUserDetailsByUserId(user.id, companySelected);
      //    setUserDetails(userDetails);
      //    setUserDetailsLoading(false);
      // } catch (error) {
      //    setUserDetailsLoading(false);
      //    console.log(error);
      // }
   }

   function getSpecificCalendar(id: string, tags?: boolean, isOnlyOneCalendar?: boolean) {
      if (tags) {
         if (tagsFilter.includes(id)) setTagsFilter((prevTags) => prevTags.filter((tag) => tag !== id));
         else setTagsFilter([...tagsFilter, id]);
         return;
      }

      if (selectedId.includes(id) && !isOnlyOneCalendar) {
         //REMOVE FILTER
         const selectedIds = selectedId.filter((idn) => idn !== id);
         const calendar = allCalendars.filter((calendar) => selectedIds.includes(calendar._id));
         setSelectedId(selectedIds);
         setSpecificCalendar(calendar);
      } else if (selectedId.length > 0) {
         //MANY GOVERNANCE SELECTED FILTER
         const selectedIds = [...selectedId, id];
         const calendar = allCalendars.filter((calendar) => selectedIds.includes(calendar._id));
         setSelectedId(selectedIds);
         setSpecificCalendar(calendar);
      } else {
         //ONE GOVERNANCE SELECTED FILTER
         const calendar = allCalendars.filter((calendar) => id === calendar._id);
         setSpecificCalendar(calendar);
         setSelectedId([id]);
      }
   }

   useEffect(() => {
      if (!selectedGovernance) return;
      const users: any = selectedGovernance.users;
      if (users !== undefined) {
         const userResourceSelection = [];
         let chargeToCheck = "";
         for (const u of users) {
            if (u.user === user.id) {
               chargeToCheck = u.charge;
            }
         }
         for (const resource of resources) {
            if (resource.charges.length === 0) continue;
            if (resource.charges.includes(chargeToCheck)) {
               const exists = userResourceSelection.some((userResource) => userResource._id === resource._id);
               if (!exists) userResourceSelection.push(resource);
            }
         }
         setResourceAccessGob(userResourceSelection);
      } else {
         setResourceAccessGob([]);
      }
   }, [selectedGovernance]);

   useEffect(() => {
      const fetchInsights = async () => {
         const insightsData = await getSavedInsights(user.id);
         setSavedInsights(insightsData);
      };
      if (user.id) fetchInsights();
   }, [user]);

   const updateSavedInsights = (updatedInsight) => {
      setSavedInsights((prev) => {
         const exists = prev.some((insight) => insight._id === updatedInsight._id);
         if (exists) {
            return prev.map((insight) => (insight._id === updatedInsight._id ? updatedInsight : insight));
         }
         return [...prev, updatedInsight];
      });
   };

   const removeFromSavedInsights = (insightId) => {
      setSavedInsights((prev) => prev.filter((insight) => insight._id !== insightId));
   };

   const getConsultiveData = useCallback(async () => {
      const groupsIds = _.uniq([
         ...companies?.filter((company) => company?.group).map((c) => c?.group),
         ...groups?.map((group) => group?._id),
      ]);

      if (groupsIds.length === 0) return;
      setisLoadingConsultiveGroup(true);
      try {
         const groupIdFound = groupsIds.find((id) => id === companySelected);
         if (!groupIdFound) return setisLoadingConsultiveGroup(false);
         const [data, groupFound] = await Promise.all([
            getConsultiveGroupById(groupIdFound),
            GetGroupById(groupIdFound),
         ]);
         if (data && groupFound) {
            const companiesResponse = await getManyCompaniesById(groupFound.companies.map((c) => c._id));
            setConsultiveGroup({
               ...data,
               name: groupFound.name,
               allCompanies: groupFound.companies.map((c) => c._id),
            });
            setConsultiveGroupCompanies(companiesResponse);
         }
      } catch (error) {
         console.error("Error fetching consultive data:", error);
      } finally {
         setisLoadingConsultiveGroup(false);
      }
   }, [companies, groups, setConsultiveGroup, companySelected]);

   useEffect(() => {
      getConsultiveData();
   }, [getConsultiveData]);

   return (
      <GovernanceContext.Provider
         value={{
            userDetails,
            setUserDetails,
            selectedGovernance,
            setSelectedGovernance,
            selectedSection,
            setSelectedSection,
            gobernanceBody,
            fetchGovernaceBody,
            isLoading,
            getUserDetailsData,
            userDetailsLoading,
            setUserDetailsLoading,
            refetch,
            setRefetch,
            personalCalendar,
            specificCalendar,
            allCalendars,
            getSpecificCalendar,
            tagsFilter,
            setTagsFilterNotes,
            tagsFilterNotes,
            textToFilter,
            setTextToFilter,
            setTagsFilter,
            startDate,
            setstartDate,
            finishDate,
            setfinishDate,
            setConfirmModal,
            confirmModal,
            refetchTasks,
            setRefetchTasks,
            refetchAdvices,
            setRefetchAdvices,
            upDownDates,
            setUpDownDates,
            refetchComments,
            setRefetchComments,
            personalSpace,
            companySelected,
            setCompanySelected,
            setSpecificCalendar,
            fileToDelete,
            setFileToDelete,
            laboralExperience,
            setLaboralExperience,
            professionalStudies,
            setProfessionalStudies,
            postgraduate,
            setPostgraduate,
            certificates,
            setCertificates,
            isUserVerified,
            setIsUserVerified,
            documentSelected,
            setDocumentSelected,
            hideSecondTopMenu,
            setHideSecondTopMenu,
            resourceAccessGob,
            setResourceAccessGob,
            selectedId,
            affairsByCompany,
            setSelectedId,
            verifyAndUpdateTags,
            notesTags,
            setNoteTags,
            eventTags,
            savedInsights,
            consultiveGroup,
            consultiveGroupCompanies,
            isLoadingConsultiveGroup,
            openGovernanceSelection,
            setOpenGovernanceSelection,
            insights,
            guides,
            updateSavedInsights,
            removeFromSavedInsights,
         }}
      >
         {children}
      </GovernanceContext.Provider>
   );
};
