import { ExpandMore, Info, PictureAsPdf, Visibility } from "@mui/icons-material";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Button,
   Divider,
   Grid,
   IconButton,
   Popover,
   Stack,
   Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getUrlS3 } from "../../lib/usersBEClient";
import StringToHTMLOutput from "../Inputs/TextEditor/StringToHTMLOutput";
import { getDateFromISODate } from "../../const/globalConst";
import { useNavigate } from "react-router-dom";
import { CustomModalComponent } from "../CustomModalComponent";
import MembersList from "../Gobierno corporativo/CorporateDataScreen/shareHolderSubComponents/membersList";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { UserContext } from "../../context/userContext";
import { SnackBarContext } from "../../context/snackBarContext";
import { CorporateDataContext, documentType } from "../../context/governanceContext/corporateDataContext";
import CorporateDataModal from "./Services/Governance/CorporateData/CorporateDataModal";

interface ModuleInfoGCProps {
   data?: any;
   document?: documentType;
   shareholdersBodyId?: string;
   setBodiesSeed: Function;
   bodiesSeed: number;
}

export const ModuleInfoGC = (props: ModuleInfoGCProps) => {
   const navigate = useNavigate();
   const { showSnackBar } = useContext(SnackBarContext);
   const { corporateData, governanceBody } = useContext(CorporateDataContext);
   const [openModal, setOpenModal] = useState(false);
   const [edition, setEdition] = useState(false);
   const [openFaculties, setOpenFaculties] = useState(false);
   const [openSocialObject, setOpenSocialObject] = useState(false);
   const [openWatchman, setOpenWatchman] = useState(false);
   const { isImplementationUser, user, companySelected } = useContext(UserContext);
   const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
   const openPopover = Boolean(anchorEl);

   const handleCloseModals = () => {
      setOpenModal(false);
      setOpenFaculties(false);
      setOpenSocialObject(false);
      setOpenWatchman(false);
   };

   useEffect(() => {
      if (
         (typeof corporateData?.incorporationYear === "string" && corporateData?.incorporationYear.length < 6) ||
         typeof corporateData?.socialObject === "string" ||
         typeof corporateData?.watchman === "string"
      ) {
         setEdition(true);
         setOpenModal(true);
         showSnackBar("Actualizar datos", true);
      }
   }, [corporateData]);

   const getURL = async (fileName: string) => {
      const activateURL = await getUrlS3(
         "files-lecosy",
         { folder: `gc/companies/${companySelected._id}/informacion-societaria` },
         fileName
      );
      window.open(activateURL, "_blank", "noopener,noreferrer");
   };

   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handlePopoverClose = () => {
      setAnchorEl(null);
   };
   return (
      <Box>
         {corporateData ? (
            <>
               <Box sx={{ bgcolor: "#EFEFEF", borderRadius: 2.5, p: 1 }}>
                  <Box sx={{ mx: 3, my: 1, display: "flex", justifyContent: "space-between" }}>
                     <Typography fontWeight={600} fontSize={17} alignSelf={"center"}>
                        Información societaria
                     </Typography>
                  </Box>
                  <Grid container sx={{ mx: 3, my: 2, rowGap: 1 }}>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           Denominación/Razón social
                        </Typography>
                        <Typography fontSize={14}>{companySelected.person_details.businessName}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           Nombre comercial:
                        </Typography>
                        <Typography fontSize={14}>{companySelected.person_details.comercialName}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           Tipo de sociedad:
                        </Typography>
                        <Typography fontSize={14}>{corporateData.societyKind}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           Figura jurídica:
                        </Typography>
                        <Typography fontSize={14}>
                           {corporateData.legalConcept === "Otro"
                              ? corporateData.legalConceptOther
                              : corporateData.legalConcept}
                        </Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           Fecha de constitución/modificación:
                        </Typography>
                        <Typography
                           fontSize={14}
                           color={
                              typeof corporateData.incorporationYear === "string" &&
                              corporateData.incorporationYear.length < 6
                                 ? "red"
                                 : null
                           }
                        >
                           {typeof corporateData.incorporationYear === "string" &&
                           corporateData.incorporationYear.length < 6
                              ? "Actualizar fecha"
                              : getDateFromISODate(corporateData.incorporationYear, true)}
                        </Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           Órgano de vigilancia:
                        </Typography>
                        {corporateData?.socialObject && (
                           <Button
                              sx={{ bgcolor: "#D9D9D9", borderRadius: 2, height: 30 }}
                              onClick={() => setOpenWatchman(true)}
                           >
                              Mostrar todo
                           </Button>
                        )}
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           {corporateData.societyKind === "Sociedad mercantil"
                              ? "Folio mercantil"
                              : "Número de inscripción"}
                           :
                        </Typography>
                        <Typography fontSize={14}>{corporateData?.businessFolio}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           Nombre del notario:
                        </Typography>
                        <Typography fontSize={14}>{corporateData?.notaryName}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           Número de notaria:
                        </Typography>
                        <Typography fontSize={14}>{corporateData?.notaryNumber}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           Tipo de acta:
                        </Typography>
                        <Typography fontSize={14}>{corporateData?.recordType}</Typography>
                     </Grid>
                  </Grid>
               </Box>

               <Accordion
                  disableGutters
                  elevation={0}
                  sx={{
                     bgcolor: "#EFEFEF",
                     borderRadius: "10px !important",
                     boxShadow: 0,
                     "&:before": { backgroundColor: "transparent !important" },
                     mt: 1,
                  }}
               >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                     <Typography fontWeight={600} fontSize={14} alignSelf={"center"}>
                        {corporateData.societyKind === "Sociedad mercantil"
                           ? "Estructura accionaria"
                           : "Distribución de capital social"}
                     </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     <Stack spacing={1}>
                        <MembersList hideFullInfo />
                        {(isImplementationUser() ||
                           user.role.includes("Administrador Único / Presidente del Consejo") ||
                           user.role.includes("Administrador del Sistema") ||
                           user.role.includes("Director General")) &&
                           governanceBody && (
                              <Stack
                                 direction={"row"}
                                 sx={{
                                    color: "#162c44",
                                    ":hover": { cursor: "pointer", color: "#162c4490" },
                                    alignItems: "center",
                                    columnGap: 0.5,
                                    px: 2,
                                    py: 1,
                                    maxWidth: 190,
                                 }}
                                 onClick={() => {
                                    if (!governanceBody) return;
                                    else if (corporateData.societyKind === "Sociedad mercantil")
                                       return navigate("../estructura-accionaria");
                                    else navigate("../distribucion-capital-social");
                                 }}
                              >
                                 <Typography fontSize={"14px"}>Editar información</Typography>
                                 <EditNoteIcon />
                              </Stack>
                           )}
                     </Stack>
                  </AccordionDetails>
               </Accordion>
               <Box
                  sx={{ bgcolor: "#EFEFEF", borderRadius: 2.5, p: 1, mt: 1, display: "flex", flexDirection: "column" }}
               >
                  <Grid container sx={{ px: 3, py: 2 }}>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           Objeto social:
                        </Typography>
                        {corporateData?.socialObject && (
                           <Button
                              sx={{ bgcolor: "#D9D9D9", borderRadius: 2, height: 30 }}
                              onClick={() => setOpenSocialObject(true)}
                           >
                              Mostrar todo
                           </Button>
                        )}
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           Pactos de socios:
                        </Typography>
                        {corporateData.filePact ? (
                           <Button
                              sx={{ height: 30 }}
                              variant="outlined"
                              onClick={async () => {
                                 getURL(corporateData.filePact.name);
                              }}
                           >
                              <Typography>Ver PDF</Typography>
                              <PictureAsPdf sx={{ mx: 1 }} />
                           </Button>
                        ) : (
                           "Sin documento"
                        )}
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           Régimen fiscal:
                        </Typography>
                        <Typography fontSize={14}>{corporateData.taxRegime}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           Órgano de administración:
                        </Typography>
                        <Typography fontSize={14}>{corporateData.administration}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           Tipo de consejo:
                        </Typography>
                        <Typography fontSize={14}>{corporateData.councilType}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           Apoderado legal:
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                           <Typography fontSize={14}>{corporateData.legalRepresentative}</Typography>

                           <IconButton onClick={handleClick} size="small">
                              <>
                                 <Visibility sx={{ color: "gray", fontSize: 20 }} />
                                 <Popover open={openPopover} anchorEl={anchorEl} onClose={handlePopoverClose}>
                                    <Stack
                                       onMouseLeave={handlePopoverClose}
                                       sx={{ width: 200, p: 3, bgcolor: "#FFFFFF", rowGap: 2, borderRadius: 3 }}
                                    >
                                       <Box>
                                          <Typography fontWeight={600} fontSize={14}>
                                             Apoderado legal:
                                          </Typography>
                                          <Typography fontSize={14}>{corporateData?.legalRepresentative}</Typography>
                                       </Box>
                                       <Box>
                                          <Typography fontWeight={600} fontSize={14}>
                                             Número de acta:
                                          </Typography>
                                          <Typography fontSize={14}>{corporateData?.actNumber}</Typography>
                                       </Box>
                                       <Box>
                                          <Typography fontWeight={600} fontSize={14}>
                                             Fecha de otorgamiento:
                                          </Typography>
                                          <Typography fontSize={14}>
                                             {getDateFromISODate(corporateData?.grantDate, true)}
                                          </Typography>
                                       </Box>
                                    </Stack>
                                    <Box></Box>
                                 </Popover>
                              </>
                           </IconButton>
                        </Box>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           Países en los que opera:
                        </Typography>
                        <Typography fontSize={14}>{corporateData.operatingCountries.join(", ")}</Typography>
                     </Grid>
                     {corporateData?.administratorFaculties && (
                        <Grid item xs={4}>
                           <Typography fontWeight={600} fontSize={14} my={1}>
                              Poderes y facultades de la administración:
                           </Typography>
                           <Button
                              sx={{ bgcolor: "#D9D9D9", borderRadius: 2, height: 30 }}
                              onClick={() => setOpenFaculties(true)}
                           >
                              Mostrar todo
                           </Button>
                        </Grid>
                     )}
                     <Grid item xs={4}>
                        <Typography fontWeight={600} fontSize={14} my={1}>
                           Domicilio físico:
                        </Typography>
                        <Typography fontSize={14}>{corporateData.physicalAddress}</Typography>
                     </Grid>
                  </Grid>
               </Box>
               <Divider sx={{ my: 2 }} />
               <Box sx={{ display: "flex", justifyContent: "space-between", alignContent: "center", mx: 1 }}>
                  <Typography fontWeight={600} fontSize={14} alignSelf={"center"}>
                     {"Última modificación: "} {getDateFromISODate(corporateData.updatedAt)}
                  </Typography>
                  <Button
                     variant="contained"
                     onClick={() => {
                        setEdition(true);
                        setOpenModal(true);
                     }}
                  >
                     Editar información
                  </Button>
               </Box>
            </>
         ) : (
            <>
               <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "left" }}>
                  <Typography fontWeight={600} fontSize={17} my={1}>
                     Información del módulo
                  </Typography>
               </Box>
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     justifyContent: "center",
                     alignItems: "center",
                     height: 200,
                     width: "100%",
                  }}
               >
                  <Info sx={{ fontSize: 60, color: "#E0E0E0" }} />
                  <Typography sx={{ color: "#E0E0E0", py: 1 }}>Sin información para mostrar</Typography>
                  <Button
                     sx={{ width: 200 }}
                     variant="contained"
                     onClick={() => {
                        setEdition(false);
                        setOpenModal(true);
                     }}
                  >
                     Agregar información
                  </Button>
               </Box>
            </>
         )}
         <CustomModalComponent title="Información societaria" open={openModal} setOpen={setOpenModal} timeStamp>
            <CorporateDataModal edition={edition} setState={setOpenModal} setBodiesSeed={props.setBodiesSeed} />
         </CustomModalComponent>
         <CustomModalComponent open={openFaculties} setOpen={handleCloseModals} title="Poderes y facultades">
            <Box sx={{ maxHeight: 500, overflowY: "auto", width: 500 }}>
               <StringToHTMLOutput text={corporateData?.administratorFaculties} sx={{ p: 3 }} />
            </Box>
            <Button
               sx={{
                  display: "flex",
                  width: "100%",
                  bgcolor: "#E0E0E0",
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
               }}
               onClick={() => setOpenFaculties(false)}
            >
               Cerrar
            </Button>
         </CustomModalComponent>
         <CustomModalComponent open={openSocialObject} setOpen={handleCloseModals} title="Objeto social">
            <Box sx={{ maxHeight: 500, overflowY: "auto", width: 500, px: 2, py: 2 }}>
               {corporateData?.socialObject ? (
                  corporateData?.socialObject.map((o, i) => (
                     <Typography key={i} fontSize={14} sx={{ p: 0.5 }}>
                        {i + 1}. {o}
                     </Typography>
                  ))
               ) : (
                  <Typography fontSize={14} sx={{ p: 0.5 }}>
                     ---
                  </Typography>
               )}
            </Box>
            <Button
               sx={{
                  display: "flex",
                  width: "100%",
                  bgcolor: "#E0E0E0",
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
               }}
               onClick={() => setOpenSocialObject(false)}
            >
               Cerrar
            </Button>
         </CustomModalComponent>
         <CustomModalComponent open={openWatchman} setOpen={handleCloseModals} title="Órgano de vigilancia">
            <Box sx={{ maxHeight: 500, overflowY: "auto", width: 320, px: 2, py: 2 }}>
               {corporateData?.watchman ? (
                  corporateData?.watchman.map((o, i) => (
                     <Typography key={i} fontSize={14} sx={{ p: 0.5 }}>
                        {i + 1}. {o}
                     </Typography>
                  ))
               ) : (
                  <Typography fontSize={14} sx={{ p: 0.5 }}>
                     ---
                  </Typography>
               )}
            </Box>
            <Button
               sx={{
                  display: "flex",
                  width: "100%",
                  bgcolor: "#E0E0E0",
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
               }}
               onClick={() => setOpenWatchman(false)}
            >
               Cerrar
            </Button>
         </CustomModalComponent>
      </Box>
   );
};
