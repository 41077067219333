import { useCallback, useContext, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
   Select,
   MenuItem,
   ListItemText,
   Stack,
   Typography,
   Button,
   Box,
   TextField,
   Grid,
   CircularProgress,
   IconButton,
   Collapse,
} from "@mui/material";
import { CustomModalComponent } from "../../CustomModalComponent";
import {
   createFolderBeneficiary,
   getCompanyById,
   getUrlS3,
   getUserByRoleAndEntity,
   getUserByUserId,
   postBeneficiary,
   getMoralBeneficiaries,
   VerifyFolderByNameAndGovernance,
   createFileAsync,
   getIdentifications,
   createIdentification,
   sendFilesByMailBc,
   createRequestChangesBeneficiary,
   createMultipleFolderBC,
   addChildToFolder,
   getBeneficiaryByRFC,
   updateBeneficiaryCompanies,
   getCaseIdentifications,
   createCaseIdentification,
   updateBeneficiaryDetails,
} from "../../../lib/usersBEClient";
import { controllingBeneficiarySchema } from "../../../lib/validations/inputSchemas";
import { SnackBarContext } from "../../../context/snackBarContext";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { uploadFileToS3 } from "../../../lib/s3Client";
import { pdf } from "@react-pdf/renderer";
import { UserContext } from "../../../context/userContext";
import { CorporateDataContext } from "../../../context/governanceContext/corporateDataContext";
import Close from "@mui/icons-material/Close";
import { beneficiaryRequestTemplate } from "../../../lib/documentsTemplates/beneficiary/beneficiaryInitialRequestTemplate";
import { shareHolderRequestTemplate } from "../../../lib/documentsTemplates/beneficiary/shareHolderInitialRequestTemplate";
import { OpenInNew } from "@mui/icons-material";
import { IBeneficiary } from "../../../types/beneficiaryTypes";
import { BeneficiaryControllerContext } from "../../../context/beneficiaryContext/beneficiaryContollerContext";

interface FilteredValues {
   companyId: string;
   user: string;
   shareHolderType: string;
   legalPerson: string;
   identification: string;
   caseIdentification: string;
   gender: string;
   rfc: string;
   businessRFC: string;
   businessName?: string;
   address?: string;
   birthDate?: string;
   placeOfBirth?: string;
   active: boolean;
   name?: any;
   lastName?: string;
   email?: string;
   additionalEmail?: string;
   phoneNumber?: string;
   folderId: string;
   moralAssociation?: any;
   selectedUser?: any;
   selectedIdentification?: any;
   selectedCaseIdentification?: any;
   fiscalAddress: string;
   fiscalCountry: string;
   constitutionCountry: string;
}

const shareHolderTypes = ["Persona física", "Persona moral"];
const genere = ["Masculino", "Femenino", "Indefinido"];
const legalPersonTypes = {
   "Persona moral": ["Fideicomiso", "Copropiedad", "Coinversión", "Asociación en participación", "Otro"],
   "Persona física": [
      "Fideicomiso",
      "Copropiedad",
      "Coinversión",
      "Asociación en participación",
      "Poderes y facultades",
      "Miembro del consejo de administración",
      "Administrador único",
      "Perfil clave",
      "Accionista / Socio / Asociado",
      "Otro",
   ],
};
interface ControllingBeneficiaryProps {
   state: boolean;
   setState: (open: boolean) => void;
   onConfirm: () => void;
   timeStamp?: boolean;
   companyId: string;
   assembly?: string;
   userId?: string;
   shareHolderType?: string;
   rfc?: string;
   businessName?: string;
   members?: any[];
   beneficiaries?: any[];
   control?: boolean;
}

export const ControllingBeneficiaryModal = (props: ControllingBeneficiaryProps) => {
   const { state, setState, onConfirm } = props;
   const { companySelected, user } = useContext(UserContext);
   const { governanceBody, corporateData } = useContext(CorporateDataContext);
   const { beneficiaries } = useContext(BeneficiaryControllerContext);
   const [shareHolderType, setShareHolderType] = useState("");
   const [gender, setGender] = useState("");
   const [legalPerson, setLegalPerson] = useState("");
   const [userSelected, setUserSelected] = useState<any>(null);
   const [error, setError] = useState<string | null>(null);
   const [phoneNumber, setPhoneNumber] = useState("");
   const [nameAndLastName, setNameAndLastName] = useState("");
   const [name, setName] = useState("");
   const [lastName, setLastName] = useState("");
   const [email, setEmail] = useState("");
   const { showSnackBar } = useContext(SnackBarContext);
   const [defined, setDefined] = useState(false);
   const [isOtherSelected, setIsOtherSelected] = useState(false);
   const [isOtherSelectedIdentifier, setIsOtherSelectedIdentifier] = useState(false);
   const [isOtherSelectedCaseIdentifier, setIsOtherSelectedCaseIdentifier] = useState(false);
   const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
   const [moralOptions, setMoralOptions] = useState([]);
   const [isLoadingMoralOptions, setIsLoadingMoralOptions] = useState(true);
   const [identifiers, setIdentifiers] = useState([]);
   const [caseIdentifiers, setCaseIdentifiers] = useState([]);
   const [isLoadingIdentifiers, setIsLoadingIdentifiers] = useState(true);
   const [foundByRFC, setFoundByRFC] = useState<IBeneficiary>(null);
   const { mutate: verifyFolder } = VerifyFolderByNameAndGovernance();

   const uploadFile = async (file, fileName, user, companyId, response) => {
      const fileExtension = file.name.split(".");
      const name = `${fileName}.${fileExtension[1].toLowerCase()}`;
      try {
         const responseFile = await createFileAsync({
            name: name,
            owner: user,
            size: file.size,
            type: file.type,
            folder: response._id,
            fileDirection: `beneficiaries/${companyId}/${response._id}`,
            beneficiary: {
               verified: undefined,
               needUpdate: false,
            },
         });
         await uploadFileToS3(responseFile.urlToUpload, file);
         return responseFile.createFile;
      } catch (error) {
         showSnackBar("Error al subir archivo", false);
      }
   };

   useEffect(() => {
      const fetchUser = async () => {
         try {
            if (props.userId !== undefined) setDefined(true);
            const userData = await getUserByUserId(props.userId);
            setPhoneNumber(userData.user.phoneNumber);
            setNameAndLastName(`${userData.user.firstName} ${userData.user.lastName}`);
            setName(userData.user.firstName);
            setLastName(userData.user.lastName);
            setEmail(userData.user.email);
            setUserSelected(userData);
         } catch (err) {
            setError("Error al obtener los datos del usuario");
         }
      };
      if (props.userId) {
         fetchUser();
      }
   }, [props.userId]);

   const handleSubmit = async (values) => {
      if (!governanceBody) return showSnackBar("Favor de registrar información societaria", true);

      if (!values.selectedIdentification && !values.identification)
         return showSnackBar("Favor de seleccionar identificador", true);

      setIsLoadingSubmit(true);
      try {
         if (foundByRFC) {
            await createMultipleFolderBC({
               folders: [
                  {
                     name: "Control",
                     children: [],
                     fileNumber: 0,
                     company: companySelected._id,
                  },
               ],
               parent: foundByRFC.folderId,
            });
            await updateBeneficiaryCompanies(foundByRFC._id, companySelected._id);
            setIsLoadingSubmit(false);
            onConfirm();
            return;
         }

         const formattedBirthDate = values.birthDate ? new Date(values.birthDate).toISOString() : undefined;
         const filteredValues: FilteredValues = {
            companyId: props.companyId,
            user: values.user,
            shareHolderType: values.shareHolderType,
            legalPerson: values.legalPerson,
            identification: values.identification,
            caseIdentification: values.caseIdentification || undefined,
            gender: values.gender,
            businessName: values.businessName || undefined,
            birthDate: formattedBirthDate || undefined,
            placeOfBirth: values.placeOfBirth || undefined,
            rfc: values.rfc || undefined,
            businessRFC: values.businessRFC || undefined,
            active: true,
            folderId: "",
            moralAssociation: values.moralAssociation,
            selectedUser: values.selectedUser,
            selectedIdentification: values.selectedIdentification,
            selectedCaseIdentification: values.selectedCaseIdentification || undefined,
            name: values.name || undefined,
            lastName: values.lastname || "",
            email: values.email || undefined,
            additionalEmail: values.email2 || undefined,
            phoneNumber: values.phoneNumber || undefined,
            fiscalAddress: values.fiscalAddress || undefined,
            fiscalCountry: values.fiscalCountry || undefined,
            constitutionCountry: values.constitutionCountry || undefined,
         };
         const folderData = {
            name: `${filteredValues.businessName || filteredValues.name}`,
            owner: user.id,
            parent: null,
         };
         verifyFolder(
            {
               governanceId: governanceBody._id,
               governanceName: "Asamblea de accionistas",
               name: "Beneficiario controlador",
            },
            {
               onSuccess: async (data) => {
                  let response = null;
                  if (!values.selectedIdentification) {
                     const identifier = await createIdentification({
                        companyId: props.companyId,
                        text: values.identification,
                        type: values.legalPerson,
                     });
                     filteredValues.identification = identifier.id;
                  } else {
                     filteredValues.identification = values.selectedIdentification._id;
                  }

                  if (legalPersonTypes["Persona moral"].includes(values.legalPerson)) {
                     if (!values.selectedCaseIdentification) {
                        const identifier = await createCaseIdentification({
                           companyId: props.companyId,
                           text: values.caseIdentification,
                           type: values.legalPerson,
                        });
                        filteredValues.caseIdentification = identifier.id;
                     } else {
                        filteredValues.caseIdentification = values.selectedCaseIdentification._id;
                     }
                  }

                  //Moral person
                  if (values.shareHolderType === shareHolderTypes[1]) {
                     const folderResponse = await createFolderBeneficiary({ ...folderData, parent: data._id });
                     filteredValues.folderId = folderResponse._id;
                     // const files = await uploadFilesAndGetIds(values, filteredValues, folderResponse);
                     const filesByMoralPerson: { fileName: string; folderId?: string }[] = [
                        { fileName: "Identificación Oficial Vigente del Representante Legal" },
                        { fileName: "Poder notarial del Representante legal" },
                        { fileName: "Acta constitutiva" },
                        { fileName: "Constancia de situación fiscal" },
                     ];
                     let filesToCreateFolder = [...filesByMoralPerson.map((file) => file.fileName), "Control"];
                     const { yearly, main } = await createMultipleFolderBC({
                        folders: filesToCreateFolder.map((folderTo) => {
                           return {
                              name: folderTo,
                              children: [],
                              fileNumber: 0,
                              company: folderTo === "Control" ? companySelected._id : null,
                           };
                        }),
                        parent: folderResponse._id,
                     });

                     if (values.acta) {
                        const index = filesByMoralPerson.findIndex((file) => file.fileName === "Acta constitutiva");
                        await uploadFile(values.acta, "Acta constitutiva", user.id, filteredValues.companyId, {
                           _id: yearly[index],
                        });
                     }
                     if (values.cdsf) {
                        const index = filesByMoralPerson.findIndex(
                           (file) => file.fileName === "Constancia de situación fiscal"
                        );
                        await uploadFile(
                           values.cdsf,
                           "Constancia de situación fiscal",
                           user.id,
                           filteredValues.companyId,
                           {
                              _id: yearly[index],
                           }
                        );
                     }

                     //Remove Control folder
                     main.pop();

                     //Add folderId for each file
                     main.forEach((folder, index) => {
                        filesByMoralPerson[index] = {
                           ...filesByMoralPerson[index],
                           folderId: folder,
                        };
                     });

                     const association = response ? response.id : filteredValues.moralAssociation?._id;
                     if (association) {
                        await addChildToFolder(
                           filteredValues.moralAssociation.folderId,
                           "Beneficiarios",
                           folderResponse._id
                        );
                     }

                     const newPerson = {
                        shareHolderType: "Persona moral",
                        user: filteredValues.user,
                        moralAssociation: filteredValues.moralAssociation?._id,
                        businessName: filteredValues.businessName,
                        name: filteredValues.name,
                        lastName: filteredValues.lastName,
                        email: filteredValues.email,
                        additionalEmail: filteredValues.additionalEmail,
                        phoneNumber: filteredValues.phoneNumber,
                        active: true,
                        folderId: folderResponse._id,
                        legalPerson: filteredValues.legalPerson,
                        companyId: [props.companyId],
                        files: [...filesByMoralPerson],
                        effectiveControl: !!props.control,
                        fiscalAddress: filteredValues.fiscalAddress,
                        fiscalCountry: filteredValues.fiscalCountry,
                        constitutionCountry: filteredValues.constitutionCountry,
                        businessRFC: filteredValues.businessRFC,
                     };
                     response = await postBeneficiary(newPerson);
                  }
                  //Register physical person
                  if (
                     (filteredValues.selectedUser && values.shareHolderType === shareHolderTypes[0]) ||
                     (filteredValues.user && values.shareHolderType === shareHolderTypes[0])
                  ) {
                     const folderData = {
                        name: filteredValues.name ? `${filteredValues.name}` : `${values.name + " " + values.lastname}`,
                        owner: user.id,
                        parent: null,
                     };
                     const folderPhysical = await createFolderBeneficiary({
                        ...folderData,
                        parent: data._id,
                     });
                     const filesByPhysicalPerson: { fileName: string; folderId?: string }[] = [
                        { fileName: "Identificación Oficial Vigente" },
                        { fileName: "Acta de nacimiento | certificado de doble nacionalidad" },
                        { fileName: "Comprobante de domicilio" },
                        { fileName: "Constancia de inexistencia de matrimonio | Acta de matrimonio" },
                        { fileName: "Identificación Oficial Vigente del Cónyuge o concubino" },
                        { fileName: "Constancia de situación fiscal" },
                     ];
                     if (
                        values.legalPerson === "Accionista / Socio / Asociado" &&
                        corporateData?.societyKind === "Sociedad mercantil"
                     ) {
                        filesByPhysicalPerson.push({ fileName: "Titulo accionario" });
                     }
                     let filesToCreateFolder = [...filesByPhysicalPerson.map((file) => file.fileName), "Control"];

                     const { yearly, main } = await createMultipleFolderBC({
                        folders: filesToCreateFolder.map((folderTo) => {
                           return {
                              name: folderTo,
                              children: [],
                              fileNumber: 0,
                              company: folderTo === "Control" ? companySelected._id : undefined,
                           };
                        }),
                        parent: folderPhysical._id,
                     });

                     //Remove Control folder
                     main.pop();

                     //Add folderId for each file
                     main.forEach((folder, index) => {
                        filesByPhysicalPerson[index] = {
                           ...filesByPhysicalPerson[index],
                           folderId: folder,
                        };
                     });

                     let newPerson = null;

                     const association = response ? response.id : filteredValues.moralAssociation?._id;
                     if (association) {
                        await addChildToFolder(
                           filteredValues.moralAssociation.folderId,
                           "Beneficiarios",
                           folderPhysical._id
                        );
                     }

                     if (filteredValues.user) {
                        newPerson = {
                           shareHolderType: "Persona fisica",
                           user: filteredValues.user,
                           additionalEmail: filteredValues.additionalEmail,
                           moralAssociation: association,
                           active: true,
                           folderId: folderPhysical._id,
                           rfc: filteredValues.rfc,
                           legalPerson: filteredValues.legalPerson,

                           companyId: [props.companyId],
                           files: [
                              ...filesByPhysicalPerson,
                              // ...files
                           ],
                           effectiveControl: !!props.control,
                        };
                     } else {
                        newPerson = {
                           shareHolderType: "Persona fisica",
                           name: filteredValues.name,
                           lastName: filteredValues.lastName,
                           email: filteredValues.email,
                           additionalEmail: filteredValues.additionalEmail,
                           gender: filteredValues.gender,
                           phoneNumber: filteredValues.phoneNumber,
                           moralAssociation: association,
                           active: true,
                           folderId: folderPhysical._id,
                           rfc: filteredValues.rfc,
                           legalPerson: filteredValues.legalPerson,
                           companyId: [props.companyId],
                           files: [
                              ...filesByPhysicalPerson,
                              // ...files
                           ],
                           effectiveControl: !!props.control,
                        };
                     }
                     const beneficiaryData = await postBeneficiary(newPerson);

                     const details = {
                        relationMoralPerson: {
                           relationWithPerson: filteredValues.legalPerson,
                           identification: filteredValues.identification,
                           identificationSub: generateIdentifier(
                              filteredValues.legalPerson,
                              filteredValues.identification,
                              filteredValues.businessName
                                 ? filteredValues.businessName
                                 : filteredValues.moralAssociation
                                 ? filteredValues.moralAssociation?.businessName
                                 : companySelected.person_details.businessName
                           ),
                           caseIdentification: filteredValues?.caseIdentification,
                        },
                     };

                     await updateBeneficiaryDetails(beneficiaryData.id, companySelected._id, details);
                     await handleFileCreations(
                        values,
                        filteredValues,
                        { _id: yearly[yearly.length - 1] },
                        beneficiaryData,
                        filesByPhysicalPerson,
                        !filteredValues.user,
                        filteredValues.email,
                        filteredValues.additionalEmail
                     );
                  }
                  setIsLoadingSubmit(false);

                  onConfirm();
               },
            }
         );
      } catch (error) {
         console.error(error);
         showSnackBar("Error al registrar beneficiario controlador", true);
         setIsLoadingSubmit(false);
      }
   };

   const handleFileCreations = async (
      values,
      filteredValues,
      folder2?,
      benef?,
      filesByPhysicalPerson?,
      extern?,
      email?,
      additionalEmail?
   ) => {
      try {
         if (folder2) {
            const companyFound = await getCompanyById(filteredValues.companyId);
            const presidentes = await getUserByRoleAndEntity("651b06f1f721833c5e8724d9", filteredValues.companyId);
            const companyResponse = companyFound.data;
            const logoUrl = await getUrlS3("images-lecosy", { folder: companyResponse._id }, "logo.png");

            let presidentName = "";
            if (presidentes && presidentes.length > 0 && presidentes[0]?.user) {
               const firstName = presidentes[0].user.firstName || "";
               const lastName = presidentes[0].user.lastName || "";
               presidentName = `${firstName} ${lastName}`.trim();
            }

            const fullName = `${values.name} ${values.lastname}`.trim();

            const blob = await pdf(
               values.legalPerson === "Accionista / Socio / Asociado"
                  ? shareHolderRequestTemplate(
                       logoUrl,
                       companyFound.data.person_details.comercialName,
                       companyFound.data.company_details.primaryColor,
                       fullName,
                       presidentName,
                       "Hermosillo",
                       "Sonora",
                       corporateData?.societyKind === "Sociedad civil"
                    )
                  : beneficiaryRequestTemplate(
                       logoUrl,
                       companyFound.data.company_details.primaryColor,
                       fullName,
                       presidentName,
                       "Hermosillo",
                       "Sonora"
                    )
            ).toBlob();

            const responseFile = await createFileAsync({
               name: "Solicitud de información beneficiario controlador.pdf",
               owner: user.id,
               size: blob.size,
               type: blob.type,
               folder: folder2._id,
               fileDirection: `beneficiaries/${filteredValues.companyId}/${folder2._id}`,
            });
            await uploadFileToS3(responseFile.urlToUpload, blob);

            const requestChangesCreation = await createRequestChangesBeneficiary({
               beneficiary: { beneficiary: benef.id, finished: false },
               files: filesByPhysicalPerson.map((fileP) => fileP.fileName),
            });

            await sendFilesByMailBc(
               {
                  beneficiary: benef.id,
                  extern: extern,
                  email: email,
                  additionalEmail: additionalEmail,
                  requestChangesId: requestChangesCreation.id,
                  company: filteredValues.companyId,
               },
               responseFile.createFile._id
            );
         }
      } catch (error) {
         console.log(error);
         showSnackBar("Error al agregar Beneficiario", true);
      }
   };

   const handleClose = () => {
      setShareHolderType("");
      setLegalPerson("");
      setState(false);
   };

   const handleSelectChange = (e, setFieldValue) => {
      const selectedName = e.target.value;
      setFieldValue("selectedUser", selectedName);
      setIsOtherSelected(selectedName === "Otro");

      const selectedMember = props.members.find((member) => member._id === selectedName._id);

      if (selectedMember) {
         setFieldValue("name", selectedName.firstName);
         setFieldValue("user", selectedMember.user);
         setFieldValue("lastname", selectedName.lastName);
         setLastName(selectedName.lastName);
         setName(selectedName.firstName);
         setPhoneNumber(selectedMember.phoneNumber);
         setFieldValue("email", selectedMember.email);
         setFieldValue("phoneNumber", selectedMember.phoneNumber);
         setEmail(selectedMember.email);
      } else {
         setFieldValue("user", "");
         setFieldValue("email", "");
         setFieldValue("phoneNumber", "");
      }
   };

   const getMoralPersons = useCallback(async () => {
      if (!state || !companySelected._id || moralOptions.length > 0) return;
      setIsLoadingMoralOptions(true);
      const response = await getMoralBeneficiaries(companySelected._id);
      setMoralOptions(response);
      setIsLoadingMoralOptions(false);
   }, [state, companySelected._id]);

   useEffect(() => {
      getMoralPersons();
   }, [getMoralPersons]);

   const fetchIdentifications = useCallback(async () => {
      if (!state || !companySelected._id || identifiers.length > 0) return;
      try {
         setIsLoadingIdentifiers(true);
         const [response, caseIdentifiersResponse] = await Promise.all([
            getIdentifications(companySelected._id),
            getCaseIdentifications(companySelected._id),
         ]);
         setIdentifiers(response);
         setCaseIdentifiers(caseIdentifiersResponse);
      } finally {
         setIsLoadingIdentifiers(false);
      }
   }, [state, companySelected._id, identifiers.length]);

   useEffect(() => {
      fetchIdentifications();
   }, [fetchIdentifications]);

   const handleFilterByType = (type, caseType = false) => {
      if (caseType) return caseIdentifiers.filter((identify) => identify.type === type);
      else return identifiers.filter((identify) => identify.type === type);
   };

   const handleFilterAssumptionsByType = (type) => {
      const value = legalPersonTypes[type];
      if (!value) return [];
      else return value;
   };

   const generateIdentifier = (suppose, description, moralAssociation) => {
      const terms = {
         Fideicomiso: "FID",
         Copropiedad: "COP",
         Coinversión: "COI",
         "Asociación en participación": "AEP",
         "Poderes y facultades": "PYF",
         "Miembro del consejo de administración": "ODA",
         "Administrador único": "ADM",
         "Perfil clave": "PCL",
         "Accionista / Socio / Asociado": "ACC",
         Otro: description ? description.substring(0, 3).toUpperCase() : "",
      };
      const companyNom = moralAssociation ? moralAssociation.substring(0, 3).toUpperCase() : "";
      const number = suppose
         ? props.beneficiaries.filter((benef) => (benef.identifierOg ? benef.identifierOg.type === suppose : false))
         : [];
      return terms[suppose] + "-" + companyNom + "-" + number.length;
   };

   const searchBeneficiaryByRFC = async (rfc: string, setFieldValue) => {
      if (!rfc) return;
      const beneficiaryFound = await getBeneficiaryByRFC(rfc);
      if (!beneficiaryFound) return setFoundByRFC(null);

      if (beneficiaries.some((b) => b._id === beneficiaryFound._id)) {
         setFieldValue("rfc", "");
         setFieldValue("businessRFC", "");
         return showSnackBar("Ya existe un beneficiario con ese RFC dentro de esta empresa", true);
      }
      setFoundByRFC(beneficiaryFound);
      setIsOtherSelected(true);

      const selectedMember = beneficiaryFound;

      if (beneficiaryFound.shareHolderType === "Persona moral") {
         const selectedName = beneficiaryFound.name;
         setFieldValue("businessName", selectedMember.businessName);
         setFieldValue("fiscalAddress", selectedMember.fiscalAddress);
         setFieldValue("fiscalCountry", selectedMember.fiscalCountry);
         setFieldValue("constitutionCountry", selectedMember.constitutionCountry);
         setFieldValue("name", selectedName);
         setFieldValue("user", selectedMember.user);
         setFieldValue("lastname", selectedMember.lastName);
         setFieldValue("email", selectedMember.email);
         setFieldValue("phoneNumber", selectedMember.phoneNumber);
      } else {
         const selectedName = beneficiaryFound.name;
         setFieldValue("selectedUser", "Otro");

         setFieldValue("name", selectedName);
         setFieldValue("user", selectedMember.user);
         setFieldValue("lastname", selectedMember.lastName);

         setName(selectedName);
         setPhoneNumber(selectedMember.phoneNumber);
         setFieldValue("email", selectedMember.email);
         setFieldValue("phoneNumber", selectedMember.phoneNumber);
         setFieldValue("gender", selectedMember.gender);
         setEmail(selectedMember.email);
      }

      if (beneficiaryFound.user) {
         const selectedName = `${beneficiaryFound.user.firstName} ${beneficiaryFound.user.lastName}`;

         setFieldValue("user", selectedMember.user);
         setFieldValue("name", selectedName);

         setLastName(beneficiaryFound.user.lastName);
         setName(beneficiaryFound.user.firstName);
         setFieldValue("email", selectedMember.user.email);
         setFieldValue("phoneNumber", selectedMember.user.phoneNumber);
      }
   };

   return (
      <CustomModalComponent
         open={state}
         setOpen={setState}
         timeStamp={props.timeStamp}
         onClose={() => setState(false)}
         title="Registro beneficiario controlador"
      >
         {!userSelected && defined ? (
            <Box display="flex" justifyContent="center" alignItems="center" padding={5}>
               <CircularProgress />
            </Box>
         ) : (
            <Formik
               initialValues={{
                  user: props.userId,
                  shareHolderType:
                     props.shareHolderType === shareHolderTypes[0]
                        ? shareHolderTypes[0]
                        : props.shareHolderType === shareHolderTypes[1]
                        ? shareHolderTypes[1]
                        : "",
                  legalPerson:
                     props.shareHolderType === shareHolderTypes[0] && props.userId
                        ? "Accionista / Socio / Asociado"
                        : "",
                  identification: "",
                  caseIdentification: "",
                  name: props.shareHolderType === shareHolderTypes[1] ? name : nameAndLastName,
                  email: email,
                  email2: "",
                  gender: "",
                  phoneNumber: props.shareHolderType === shareHolderTypes[0] ? phoneNumber : phoneNumber,
                  birthDate: "",
                  placeOfBirth: "",
                  // address: "",
                  businessName: props.businessName,
                  rfc: props.shareHolderType === shareHolderTypes[0] && props.userId ? props.rfc : undefined,
                  businessRFC: props.shareHolderType === shareHolderTypes[1] && props.userId ? props.rfc : undefined,
                  lastname: lastName,
                  acta: null,
                  cdsf: null,
                  contrato: null,
                  moralAssociation: null,
                  selectedUser: null,
                  selectedIdentification: null,
                  selectedCaseIdentification: null,
                  fiscalAddress: "",
                  fiscalCountry: "",
                  constitutionCountry: "",
               }}
               validationSchema={controllingBeneficiarySchema}
               onSubmit={handleSubmit}
            >
               {({ handleChange, setFieldValue, handleBlur, values }) => {
                  return (
                     <Form>
                        <Box
                           sx={{
                              display: "flex",
                              flexDirection: "column",
                              maxHeight: 500,
                              width: 750,
                              px: 4,
                              py: 2,
                              rowGap: 2,
                              bgcolor: "white",
                              overflow: "auto",
                           }}
                        >
                           <Stack spacing={2}>
                              <Box sx={{ padding: 0, margin: 0 }}>
                                 <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                       <Stack spacing={1} sx={{ width: "100%" }}>
                                          <Typography sx={{ color: "#585757" }}>
                                             Tipo de beneficiario controlador
                                          </Typography>
                                          <Select
                                             size="small"
                                             variant="outlined"
                                             name="shareHolderType"
                                             value={values.shareHolderType}
                                             onChange={(e) => {
                                                handleChange(e);
                                                setShareHolderType(e.target.value);
                                             }}
                                             onBlur={handleBlur}
                                             disabled={defined}
                                          >
                                             {shareHolderTypes.map((e) => (
                                                <MenuItem key={e} value={e}>
                                                   <ListItemText primary={e} sx={{ my: -0.1 }} />
                                                </MenuItem>
                                             ))}
                                          </Select>
                                          <ErrorMessage name="shareHolderType">
                                             {(msg) => <Typography color="error">{msg}</Typography>}
                                          </ErrorMessage>
                                       </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                       <Stack spacing={1} sx={{ width: "100%" }}>
                                          <Typography sx={{ color: "#585757" }}>Supuesto</Typography>
                                          <Select
                                             sx={{ width: "100%" }}
                                             size="small"
                                             variant="outlined"
                                             name="legalPerson"
                                             value={values.legalPerson}
                                             onChange={(e) => {
                                                handleChange(e);
                                                setLegalPerson(e.target.value);
                                             }}
                                             renderValue={(selected) => selected}
                                             onBlur={handleBlur}
                                             disabled={!values.shareHolderType || props.userId !== undefined}
                                          >
                                             {handleFilterAssumptionsByType(values.shareHolderType).map((e) => (
                                                <MenuItem key={e} value={e}>
                                                   <ListItemText primary={e} sx={{ my: -0.1 }} />
                                                </MenuItem>
                                             ))}
                                          </Select>
                                          <ErrorMessage name="legalPerson">
                                             {(msg) => <Typography color="error">{msg}</Typography>}
                                          </ErrorMessage>
                                       </Stack>
                                    </Grid>
                                 </Grid>
                              </Box>
                              <Collapse
                                 in={
                                    !!values.legalPerson &&
                                    legalPersonTypes["Persona moral"].includes(values.legalPerson)
                                 }
                                 unmountOnExit
                                 sx={{ width: "100%" }}
                              >
                                 <Box sx={{ padding: 0, margin: 0 }}>
                                    <Grid container spacing={3}>
                                       <Grid item sm={12}>
                                          <Stack direction={"row"}>
                                             <Collapse in={!!values.legalPerson} unmountOnExit sx={{ width: "100%" }}>
                                                <Typography sx={{ color: "#585757" }} marginBottom={1}>
                                                   Identificación del supuesto
                                                </Typography>
                                                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                                                   {isLoadingIdentifiers ? (
                                                      <CircularProgress />
                                                   ) : (
                                                      <>
                                                         {!isOtherSelectedCaseIdentifier ? (
                                                            <Field
                                                               as={Select}
                                                               sx={{ width: "100%" }}
                                                               size="small"
                                                               variant="outlined"
                                                               name="selectedCaseIdentification"
                                                               onChange={(e) => {
                                                                  if (e.target.value === "Otro") {
                                                                     setIsOtherSelectedCaseIdentifier(true);
                                                                     setFieldValue("selectedCaseIdentification", null);
                                                                  } else {
                                                                     setFieldValue(
                                                                        "selectedCaseIdentification",
                                                                        e.target.value
                                                                     );
                                                                  }
                                                               }}
                                                               onBlur={handleBlur}
                                                               value={values.selectedCaseIdentification}
                                                            >
                                                               {handleFilterByType(values?.legalPerson, true)?.map(
                                                                  (identifier) => (
                                                                     <MenuItem key={identifier._id} value={identifier}>
                                                                        <ListItemText
                                                                           primary={identifier.text}
                                                                           sx={{ my: -0.1 }}
                                                                        />
                                                                     </MenuItem>
                                                                  )
                                                               )}
                                                               <MenuItem value="Otro">
                                                                  <ListItemText primary="Otro" sx={{ my: -0.1 }} />
                                                               </MenuItem>
                                                            </Field>
                                                         ) : (
                                                            <Stack
                                                               direction={"row"}
                                                               sx={{
                                                                  alignItems: "center",
                                                                  columnGap: 1,
                                                                  width: "100%",
                                                               }}
                                                            >
                                                               <Field
                                                                  as={TextField}
                                                                  fullWidth
                                                                  type="text"
                                                                  size="small"
                                                                  name="caseIdentification"
                                                                  placeholder="XXXXXXXX"
                                                                  value={values.caseIdentification}
                                                               />
                                                               <IconButton
                                                                  onClick={() => {
                                                                     setFieldValue("caseIdentification", "");
                                                                     setFieldValue("selectedCaseIdentification", null);
                                                                     setIsOtherSelectedCaseIdentifier(false);
                                                                  }}
                                                               >
                                                                  <Close color="error" aria-label="remover" />
                                                               </IconButton>
                                                            </Stack>
                                                         )}
                                                      </>
                                                   )}
                                                </Stack>
                                             </Collapse>
                                          </Stack>
                                       </Grid>
                                    </Grid>
                                 </Box>
                              </Collapse>
                              <Collapse in={!!values.legalPerson} unmountOnExit sx={{ width: "100%" }}>
                                 <Box sx={{ padding: 0, margin: 0 }}>
                                    <Grid container spacing={3}>
                                       <Grid item sm={12}>
                                          <Stack direction={"row"}>
                                             <Collapse in={!!values.legalPerson} unmountOnExit sx={{ width: "100%" }}>
                                                <Typography sx={{ color: "#585757" }} marginBottom={1}>
                                                   Nombre identificador
                                                </Typography>
                                                <Stack direction={"row"} sx={{ alignItems: "center" }}>
                                                   <Grid container spacing={1}>
                                                      <Grid item sm={3}>
                                                         <Box
                                                            sx={{
                                                               p: 1,
                                                               border: 1,
                                                               borderColor: "#CBD5E0",
                                                               bgcolor: "#F6F7FB",
                                                               borderRadius: 1,
                                                               display: "flex",
                                                               justifyContent: "center",
                                                               alignItems: "center",
                                                               maxHeight: 40,
                                                            }}
                                                         >
                                                            <Typography>
                                                               {generateIdentifier(
                                                                  values.legalPerson,
                                                                  values.identification,
                                                                  values.businessName
                                                                     ? values.businessName
                                                                     : values.moralAssociation
                                                                     ? values.moralAssociation?.businessName
                                                                     : companySelected.person_details.businessName
                                                               )}
                                                            </Typography>
                                                         </Box>
                                                      </Grid>
                                                      <Grid item sm={9}>
                                                         {isLoadingIdentifiers ? (
                                                            <CircularProgress />
                                                         ) : (
                                                            <>
                                                               {!isOtherSelectedIdentifier ? (
                                                                  <Field
                                                                     as={Select}
                                                                     sx={{ width: "100%" }}
                                                                     size="small"
                                                                     variant="outlined"
                                                                     name="selectedIdentification"
                                                                     onChange={(e) => {
                                                                        if (e.target.value === "Otro") {
                                                                           setIsOtherSelectedIdentifier(true);
                                                                           setFieldValue(
                                                                              "selectedIdentification",
                                                                              null
                                                                           );
                                                                        } else {
                                                                           setFieldValue(
                                                                              "selectedIdentification",
                                                                              e.target.value
                                                                           );
                                                                        }
                                                                     }}
                                                                     onBlur={handleBlur}
                                                                     value={values.selectedIdentification}
                                                                  >
                                                                     {Array.isArray(
                                                                        handleFilterByType(values?.legalPerson)
                                                                     ) &&
                                                                        handleFilterByType(values?.legalPerson).map(
                                                                           (identifier) =>
                                                                              identifier?._id && identifier?.text ? (
                                                                                 <MenuItem
                                                                                    key={identifier._id}
                                                                                    value={identifier}
                                                                                 >
                                                                                    <ListItemText
                                                                                       primary={identifier.text}
                                                                                       sx={{ my: -0.1 }}
                                                                                    />
                                                                                 </MenuItem>
                                                                              ) : null
                                                                        )}

                                                                     <MenuItem value="Otro">
                                                                        <ListItemText
                                                                           primary="Otro"
                                                                           sx={{ my: -0.1 }}
                                                                        />
                                                                     </MenuItem>
                                                                  </Field>
                                                               ) : (
                                                                  <Stack
                                                                     direction={"row"}
                                                                     sx={{ alignItems: "center", columnGap: 2 }}
                                                                  >
                                                                     <Field
                                                                        as={TextField}
                                                                        fullWidth
                                                                        type="text"
                                                                        size="small"
                                                                        name="identification"
                                                                        placeholder="XXXXXXXX"
                                                                        value={values.identification}
                                                                     />
                                                                     <IconButton
                                                                        onClick={() => {
                                                                           setFieldValue("identification", "");
                                                                           setFieldValue(
                                                                              "selectedIdentification",
                                                                              null
                                                                           );
                                                                           setIsOtherSelectedIdentifier(false);
                                                                        }}
                                                                     >
                                                                        <Close color="error" aria-label="remover" />
                                                                     </IconButton>
                                                                  </Stack>
                                                               )}
                                                            </>
                                                         )}
                                                      </Grid>
                                                   </Grid>
                                                </Stack>
                                             </Collapse>
                                          </Stack>
                                       </Grid>
                                    </Grid>
                                 </Box>
                              </Collapse>
                              <Stack spacing={1} sx={{ width: "100%" }}>
                                 <Typography sx={{ color: "#585757" }}>Asociar a empresa</Typography>
                                 {isLoadingMoralOptions ? (
                                    <CircularProgress />
                                 ) : (
                                    <Stack direction={"row"} sx={{ alignItems: "center", columnGap: 2 }}>
                                       <Select
                                          size="small"
                                          variant="outlined"
                                          name="moralAssociation"
                                          value={values.moralAssociation}
                                          onChange={(e) => {
                                             handleChange(e);
                                          }}
                                          onBlur={handleBlur}
                                          disabled={defined}
                                          fullWidth
                                       >
                                          {moralOptions.map((e) => (
                                             <MenuItem key={e} value={e}>
                                                <ListItemText primary={e.businessName} sx={{ my: -0.1 }} />
                                             </MenuItem>
                                          ))}
                                       </Select>
                                       {values.moralAssociation ? (
                                          <IconButton
                                             onClick={() => {
                                                setFieldValue("moralAssociation", null);
                                             }}
                                          >
                                             <Close color="error" aria-label="remover" />
                                          </IconButton>
                                       ) : null}
                                    </Stack>
                                 )}
                                 <ErrorMessage name="moralAssociation">
                                    {(msg) => <Typography color="error">{msg}</Typography>}
                                 </ErrorMessage>
                              </Stack>

                              <Stack spacing={1} sx={{ width: "100%" }}>
                                 {shareHolderType !== shareHolderTypes[1] ? (
                                    <>
                                       <Box sx={{ paddingTop: 0, margin: 0 }}>
                                          <Grid container spacing={3}>
                                             <Grid item xs={6}>
                                                <Typography sx={{ color: "#585757" }} marginBottom={1}>
                                                   Nombre del beneficiario controlador
                                                </Typography>
                                                {!props.members ? (
                                                   <TextField
                                                      fullWidth
                                                      type="text"
                                                      size="small"
                                                      name="name"
                                                      placeholder="Nombre"
                                                      value={values.name}
                                                      onBlur={handleBlur}
                                                      onChange={handleChange}
                                                      disabled={
                                                         (props.shareHolderType === shareHolderTypes[0] && defined) ||
                                                         foundByRFC !== null
                                                      }
                                                   />
                                                ) : (
                                                   <>
                                                      {!isOtherSelected ? (
                                                         <Stack
                                                            direction={"row"}
                                                            sx={{ alignItems: "center", columnGap: 2 }}
                                                         >
                                                            <Field
                                                               as={Select}
                                                               sx={{ minWidth: 330 }}
                                                               size="small"
                                                               variant="outlined"
                                                               name="selectedUser"
                                                               onChange={(e) => handleSelectChange(e, setFieldValue)}
                                                               onBlur={handleBlur}
                                                               value={values.selectedUser}
                                                               renderValue={(member) =>
                                                                  `${member.firstName} ${member.lastName}`
                                                               }
                                                            >
                                                               {props.members
                                                                  .filter((member) => !member.businessName)
                                                                  .map((member) => (
                                                                     <MenuItem key={member._id} value={member}>
                                                                        <ListItemText
                                                                           primary={`${member.firstName} ${member.lastName}`}
                                                                           sx={{ my: -0.1 }}
                                                                        />
                                                                     </MenuItem>
                                                                  ))}
                                                               <MenuItem value="Otro">
                                                                  <ListItemText primary="Otro" sx={{ my: -0.1 }} />
                                                               </MenuItem>
                                                            </Field>
                                                            {/* {!!values.selectedUser ? (
                                                               <IconButton
                                                                  onClick={() => {
                                                                     setFieldValue("name", "");
                                                                     setFieldValue("lastname", "");
                                                                     setFieldValue("email", "");
                                                                     setFieldValue("phoneNumber", "");
                                                                     setFieldValue("rfc", "");
                                                                     setEmail("");
                                                                     setPhoneNumber("");
                                                                     setFieldValue("selectedUser", null);
                                                                     setIsOtherSelected(false);
                                                                     setFoundByRFC(null);
                                                                  }}
                                                               >
                                                                  <Close color="error" aria-label="remover" />
                                                               </IconButton>
                                                            ) : null} */}
                                                         </Stack>
                                                      ) : (
                                                         <Stack
                                                            direction={"row"}
                                                            sx={{ alignItems: "center", columnGap: 2 }}
                                                         >
                                                            <TextField
                                                               fullWidth
                                                               type="text"
                                                               size="small"
                                                               name="name"
                                                               placeholder="Nombre"
                                                               value={values.name}
                                                               onBlur={handleBlur}
                                                               onChange={handleChange}
                                                               disabled={
                                                                  props.shareHolderType === shareHolderTypes[0] ||
                                                                  foundByRFC !== null
                                                               }
                                                            />
                                                            <IconButton
                                                               onClick={() => {
                                                                  setFieldValue("name", "");
                                                                  setFieldValue("lastname", "");
                                                                  setFieldValue("selectedUser", "");
                                                                  setFieldValue("email", "");
                                                                  setFieldValue("gender", "");
                                                                  setFieldValue("phoneNumber", "");
                                                                  setFieldValue("rfc", "");
                                                                  setIsOtherSelected(false);
                                                                  setFoundByRFC(null);
                                                               }}
                                                            >
                                                               <Close color="error" aria-label="remover" />
                                                            </IconButton>
                                                         </Stack>
                                                      )}
                                                   </>
                                                )}
                                                <ErrorMessage name="name">
                                                   {(msg) => <Typography color="error">{msg}</Typography>}
                                                </ErrorMessage>
                                             </Grid>
                                             <Grid item xs={6}>
                                                <Typography sx={{ color: "#585757" }} marginBottom={1}>
                                                   RFC
                                                </Typography>
                                                <Field
                                                   as={TextField}
                                                   fullWidth
                                                   type="text"
                                                   size="small"
                                                   name="rfc"
                                                   placeholder="XXXXXXXXXX"
                                                   value={values.rfc}
                                                   onBlur={() => searchBeneficiaryByRFC(values.rfc, setFieldValue)}
                                                   onChange={handleChange}
                                                   disabled={props.rfc}
                                                   sx={{ width: 330 }}
                                                />
                                                <ErrorMessage name="rfc">
                                                   {(msg) => <Typography color="error">{msg}</Typography>}
                                                </ErrorMessage>
                                             </Grid>
                                             <Grid item xs={6}>
                                                <Typography sx={{ color: "#585757" }} marginBottom={1}>
                                                   Correo electrónico
                                                </Typography>
                                                <Field
                                                   as={TextField}
                                                   fullWidth
                                                   type="email"
                                                   size="small"
                                                   name="email"
                                                   placeholder="correo@correo.com.mx"
                                                   value={values.email}
                                                   onBlur={handleBlur}
                                                   onChange={handleChange}
                                                   disabled={
                                                      props.shareHolderType === shareHolderTypes[0] ||
                                                      email ||
                                                      foundByRFC
                                                   }
                                                />
                                                <ErrorMessage name="email">
                                                   {(msg) => <Typography color="error">{msg}</Typography>}
                                                </ErrorMessage>
                                             </Grid>
                                             <Grid item xs={6}>
                                                <Typography sx={{ color: "#585757" }} marginBottom={1}>
                                                   Correo electrónico alternativo
                                                </Typography>
                                                <Field
                                                   as={TextField}
                                                   fullWidth
                                                   type="email2"
                                                   size="small"
                                                   name="email2"
                                                   placeholder="correo@correo.com.mx"
                                                   value={values.email2}
                                                   onBlur={handleBlur}
                                                   onChange={handleChange}
                                                />
                                                <ErrorMessage name="email2">
                                                   {(msg) => <Typography color="error">{msg}</Typography>}
                                                </ErrorMessage>
                                             </Grid>

                                             {!isOtherSelected ? null : (
                                                <>
                                                   <Grid item xs={6}>
                                                      <Typography sx={{ color: "#585757" }} marginBottom={1}>
                                                         Teléfono
                                                      </Typography>
                                                      <Field
                                                         as={TextField}
                                                         fullWidth
                                                         type="number"
                                                         size="small"
                                                         name="phoneNumber"
                                                         placeholder="XXXXXXXX"
                                                         value={values.phoneNumber}
                                                         onBlur={handleBlur}
                                                         onChange={handleChange}
                                                         disabled={
                                                            props.shareHolderType === shareHolderTypes[0] ||
                                                            email ||
                                                            foundByRFC
                                                         }
                                                      />
                                                      <ErrorMessage name="phoneNumber">
                                                         {(msg) => <Typography color="error">{msg}</Typography>}
                                                      </ErrorMessage>
                                                   </Grid>
                                                   {/* <Grid item xs={6}>
                                                      <Typography sx={{ color: "#030202" }} marginBottom={1}>
                                                         Lugar de nacimiento
                                                      </Typography>
                                                      <Field
                                                         as={TextField}
                                                         fullWidth
                                                         type="text"
                                                         size="small"
                                                         name="placeOfBirth"
                                                         placeholder="XXXXXXXX"
                                                         value={values.placeOfBirth}
                                                         onBlur={handleBlur}
                                                         onChange={handleChange}
                                                      />
                                                      <ErrorMessage name="placeOfBirth">
                                                         {(msg) => <Typography color="error">{msg}</Typography>}
                                                      </ErrorMessage>
                                                   </Grid> */}
                                                </>
                                             )}

                                             {!isOtherSelected ? null : (
                                                <>
                                                   <Grid item xs={6}>
                                                      <Typography sx={{ color: "#585757" }} marginBottom={1}>
                                                         Género
                                                      </Typography>

                                                      <Select
                                                         size="small"
                                                         variant="outlined"
                                                         name="gender"
                                                         value={values.gender}
                                                         onChange={(e) => {
                                                            handleChange(e);
                                                            setGender(e.target.value);
                                                         }}
                                                         onBlur={handleBlur}
                                                         fullWidth
                                                         disabled={foundByRFC !== null}
                                                      >
                                                         {genere.map((e) => (
                                                            <MenuItem key={e} value={e}>
                                                               <ListItemText primary={e} sx={{ my: -0.1 }} />
                                                            </MenuItem>
                                                         ))}
                                                      </Select>
                                                      <ErrorMessage name="gender">
                                                         {(msg) => <Typography color="error">{msg}</Typography>}
                                                      </ErrorMessage>
                                                   </Grid>
                                                   {/* <Grid item xs={6}>
                                                      <Typography sx={{ color: "#585757" }} marginBottom={1}>
                                                         Fecha de nacimiento
                                                      </Typography>
                                                      <Field
                                                         as={TextField}
                                                         fullWidth
                                                         type="date"
                                                         size="small"
                                                         name="birthDate"
                                                         value={values.birthDate}
                                                         onBlur={handleBlur}
                                                         onChange={handleChange}
                                                      />
                                                      <ErrorMessage name="birthDate">
                                                         {(msg) => <Typography color="error">{msg}</Typography>}
                                                      </ErrorMessage>
                                                   </Grid> */}
                                                </>
                                             )}
                                          </Grid>
                                       </Box>
                                    </>
                                 ) : (
                                    <>
                                       <Box sx={{ paddingTop: 0, margin: 0 }}>
                                          <Grid container spacing={3}>
                                             <Grid item xs={6}>
                                                <Typography sx={{ color: "#585757" }} marginBottom={1}>
                                                   Razón/Denominación Social
                                                </Typography>
                                                <Field
                                                   as={TextField}
                                                   fullWidth
                                                   type="text"
                                                   size="small"
                                                   name="businessName"
                                                   placeholder="Nombre"
                                                   value={values.businessName}
                                                   onBlur={handleBlur}
                                                   onChange={handleChange}
                                                   disabled={props.businessName || foundByRFC !== null}
                                                />
                                                <ErrorMessage name="businessName">
                                                   {(msg) => <Typography color="error">{msg}</Typography>}
                                                </ErrorMessage>
                                             </Grid>

                                             <Grid item xs={6}>
                                                <Typography sx={{ color: "#585757", marginBottom: 1 }}>
                                                   RFC o su equivalente
                                                </Typography>
                                                <Field
                                                   as={TextField}
                                                   sx={{ minWidth: 330 }}
                                                   type="text"
                                                   size="small"
                                                   name="businessRFC"
                                                   placeholder="XXXXXXXXXX"
                                                   value={values.businessRFC}
                                                   onBlur={() =>
                                                      searchBeneficiaryByRFC(values.businessRFC, setFieldValue)
                                                   }
                                                   onChange={handleChange}
                                                   disabled={props.rfc}
                                                />
                                                <ErrorMessage name="businessRFC">
                                                   {(msg) => <Typography color="error">{msg}</Typography>}
                                                </ErrorMessage>
                                                {foundByRFC !== null ? (
                                                   <IconButton
                                                      onClick={() => {
                                                         setFieldValue("name", "");
                                                         setFieldValue("lastname", "");
                                                         setPhoneNumber("");
                                                         setEmail("");
                                                         setFieldValue("email", "");
                                                         setFieldValue("phoneNumber", "");
                                                         setFieldValue("selectedUser", null);
                                                         setFieldValue("rfc", "");
                                                         setFieldValue("businessRFC", "");
                                                         setPhoneNumber("");
                                                         setFoundByRFC(null);
                                                      }}
                                                   >
                                                      <Close color="error" aria-label="remover" />
                                                   </IconButton>
                                                ) : null}
                                             </Grid>
                                             <Grid item xs={6}>
                                                <Typography marginTop={1} sx={{ color: "#585757" }} marginBottom={1}>
                                                   Acta constitutiva
                                                </Typography>

                                                <Button
                                                   variant="outlined"
                                                   component="label"
                                                   fullWidth
                                                   endIcon={<AttachFileIcon sx={{ color: "#B0B0B0" }} />}
                                                   sx={{
                                                      display: "flex",
                                                      height: "40px",
                                                      justifyContent: "space-between",
                                                      alignItems: "center",
                                                      color: "#B0B0B0",
                                                      borderColor: "#B0B0B0",
                                                      "&:hover": {
                                                         borderColor: "#B0B0B0",
                                                      },
                                                   }}
                                                   disabled={foundByRFC !== null}
                                                >
                                                   <Typography sx={{ flex: 1, textAlign: "left" }}>
                                                      Subir archivo
                                                   </Typography>
                                                   <input
                                                      type="file"
                                                      hidden
                                                      accept="application/pdf"
                                                      onChange={(event) => {
                                                         const file = event.currentTarget.files[0];
                                                         if (file) {
                                                            if (file.type !== "application/pdf") {
                                                               showSnackBar("Solo se permiten archivos PDF.", true);
                                                               return;
                                                            }
                                                            if (file.size > 200 * 1024 * 1024) {
                                                               showSnackBar(
                                                                  "El archivo debe ser menor de 200 MB.",
                                                                  true
                                                               );
                                                               return;
                                                            }
                                                            setFieldValue("acta", file);
                                                         }
                                                      }}
                                                   />
                                                </Button>
                                                <FileSelectedCollapse
                                                   file={values.acta}
                                                   onClick={() => {
                                                      setFieldValue("acta", null);
                                                   }}
                                                />
                                             </Grid>
                                             <Grid item xs={6}>
                                                <Typography sx={{ color: "#585757", my: 1 }}>
                                                   País o jurisdicción de constitución o registro
                                                </Typography>
                                                <Field
                                                   as={TextField}
                                                   fullWidth
                                                   type="text"
                                                   size="small"
                                                   name="constitutionCountry"
                                                   placeholder="XXXXXX"
                                                   value={values.constitutionCountry}
                                                   onBlur={handleBlur}
                                                   onChange={handleChange}
                                                   disabled={foundByRFC !== null}
                                                />
                                                <ErrorMessage name="constitutionCountry">
                                                   {(msg) => <Typography color="error">{msg}</Typography>}
                                                </ErrorMessage>
                                             </Grid>
                                             <Grid item xs={6}>
                                                <Typography sx={{ color: "#585757", my: 1, mb: 4 }}>
                                                   Constancia de situación fiscal
                                                </Typography>
                                                <Button
                                                   variant="outlined"
                                                   component="label"
                                                   fullWidth
                                                   endIcon={<AttachFileIcon sx={{ color: "#B0B0B0" }} />}
                                                   sx={{
                                                      display: "flex",
                                                      height: "40px",
                                                      justifyContent: "space-between",
                                                      alignItems: "center",
                                                      color: "#B0B0B0",
                                                      borderColor: "#B0B0B0",
                                                      "&:hover": {
                                                         borderColor: "#B0B0B0",
                                                      },
                                                   }}
                                                   disabled={foundByRFC !== null}
                                                >
                                                   <Typography sx={{ flex: 1, textAlign: "left" }}>
                                                      Subir archivo
                                                   </Typography>
                                                   <input
                                                      type="file"
                                                      hidden
                                                      accept="application/pdf"
                                                      onChange={(event) => {
                                                         const file = event.currentTarget.files[0];
                                                         if (file) {
                                                            if (file.type !== "application/pdf") {
                                                               showSnackBar("Solo se permiten archivos PDF.", true);
                                                               return;
                                                            }
                                                            if (file.size > 200 * 1024 * 1024) {
                                                               showSnackBar(
                                                                  "El archivo debe ser menor de 200 MB.",
                                                                  true
                                                               );
                                                               return;
                                                            }
                                                            setFieldValue("cdsf", event.currentTarget.files[0]);
                                                         }
                                                      }}
                                                   />
                                                </Button>
                                                <FileSelectedCollapse
                                                   file={values.cdsf}
                                                   onClick={() => {
                                                      setFieldValue("cdsf", null);
                                                   }}
                                                />
                                             </Grid>
                                             <Grid item xs={6}>
                                                <Typography sx={{ color: "#585757", my: 1 }}>
                                                   País o jurisdicción de residencia para efectos fiscales. 
                                                </Typography>
                                                <Field
                                                   as={TextField}
                                                   fullWidth
                                                   type="text"
                                                   size="small"
                                                   name="fiscalCountry"
                                                   placeholder="XXXXXX"
                                                   value={values.fiscalCountry}
                                                   onBlur={handleBlur}
                                                   onChange={handleChange}
                                                   disabled={foundByRFC !== null}
                                                />
                                                <ErrorMessage name="fiscalCountry">
                                                   {(msg) => <Typography color="error">{msg}</Typography>}
                                                </ErrorMessage>
                                             </Grid>

                                             <Grid item xs={6}>
                                                <Typography sx={{ color: "#585757", my: 1, mb: 1 }}>
                                                   Domicilio fiscal
                                                </Typography>
                                                <Field
                                                   as={TextField}
                                                   fullWidth
                                                   type="text"
                                                   size="small"
                                                   name="fiscalAddress"
                                                   placeholder="XXXXXX"
                                                   value={values.fiscalAddress}
                                                   onBlur={handleBlur}
                                                   onChange={handleChange}
                                                   disabled={foundByRFC !== null}
                                                />
                                                <ErrorMessage name="fiscalAddress">
                                                   {(msg) => <Typography color="error">{msg}</Typography>}
                                                </ErrorMessage>
                                             </Grid>
                                          </Grid>
                                          <Typography sx={{ my: 2 }}>
                                             <strong>Apoderado legal</strong>
                                          </Typography>
                                          <Grid container spacing={3}>
                                             <Grid item sm={12}>
                                                <Typography sx={{ color: "#585757" }}>Usuario existente</Typography>
                                                <Stack direction={"row"} sx={{ alignItems: "center", columnGap: 2 }}>
                                                   <Field
                                                      as={Select}
                                                      sx={{ width: "100%" }}
                                                      size="small"
                                                      variant="outlined"
                                                      name="selectedUser"
                                                      onChange={(e) => handleSelectChange(e, setFieldValue)}
                                                      onBlur={handleBlur}
                                                      value={values.selectedUser}
                                                      disabled={foundByRFC !== null}
                                                   >
                                                      {props.members
                                                         // .filter((member) => member.controllerB)}
                                                         .filter((member) => !member.businessName)
                                                         .map((member) => (
                                                            <MenuItem key={member._id} value={member}>
                                                               <ListItemText
                                                                  primary={`${member.firstName} ${member.lastName}`}
                                                                  sx={{ my: -0.1 }}
                                                               />
                                                            </MenuItem>
                                                         ))}
                                                   </Field>
                                                   {!!values.selectedUser ? (
                                                      <IconButton
                                                         onClick={() => {
                                                            setFieldValue("name", "");
                                                            setFieldValue("lastname", "");
                                                            setPhoneNumber("");
                                                            setEmail("");
                                                            setFieldValue("email", "");
                                                            setFieldValue("phoneNumber", "");
                                                            setFieldValue("selectedUser", null);
                                                            setFieldValue("rfc", "");
                                                            setFieldValue("businessRFC", "");
                                                            setPhoneNumber("");
                                                            setFoundByRFC(null);
                                                         }}
                                                      >
                                                         <Close color="error" aria-label="remover" />
                                                      </IconButton>
                                                   ) : null}
                                                </Stack>
                                             </Grid>
                                             <Grid item sm={6}>
                                                <Typography sx={{ color: "#585757" }}>Nombre</Typography>
                                                <Field
                                                   as={TextField}
                                                   fullWidth
                                                   type="text"
                                                   size="small"
                                                   name="name"
                                                   placeholder="XXXXXXXX"
                                                   value={values.name}
                                                   onBlur={handleBlur}
                                                   onChange={handleChange}
                                                   disabled={name || foundByRFC !== null}
                                                />
                                                <ErrorMessage name="name">
                                                   {(msg) => <Typography color="error">{msg}</Typography>}
                                                </ErrorMessage>
                                             </Grid>
                                             <Grid item sm={6}>
                                                <Typography sx={{ color: "#585757" }}>Apellido</Typography>
                                                <Field
                                                   as={TextField}
                                                   fullWidth
                                                   type="text"
                                                   size="small"
                                                   name="lastname"
                                                   placeholder="XXXXXXXX"
                                                   value={values.lastname}
                                                   onBlur={handleBlur}
                                                   onChange={handleChange}
                                                   disabled={lastName || foundByRFC !== null}
                                                />
                                                <ErrorMessage name="lastname">
                                                   {(msg) => <Typography color="error">{msg}</Typography>}
                                                </ErrorMessage>
                                             </Grid>
                                             <Grid item sm={6}>
                                                <Typography sx={{ color: "#585757" }}>Telefono</Typography>
                                                <Field
                                                   as={TextField}
                                                   fullWidth
                                                   type="number"
                                                   size="small"
                                                   name="phoneNumber"
                                                   placeholder="XXXXXXXXXX"
                                                   value={values.phoneNumber}
                                                   onBlur={handleBlur}
                                                   onChange={handleChange}
                                                   disabled={phoneNumber || foundByRFC !== null}
                                                />
                                                <ErrorMessage name="phoneNumber">
                                                   {(msg) => <Typography color="error">{msg}</Typography>}
                                                </ErrorMessage>
                                             </Grid>

                                             <Grid item sm={6}>
                                                <Typography sx={{ color: "#585757" }}>Correo electrónico</Typography>
                                                <Field
                                                   as={TextField}
                                                   fullWidth
                                                   type="text"
                                                   size="small"
                                                   name="email"
                                                   placeholder="correo@correo.com.mx"
                                                   value={values.email}
                                                   onBlur={handleBlur}
                                                   onChange={handleChange}
                                                   disabled={email || foundByRFC !== null}
                                                />
                                                <ErrorMessage name="email">
                                                   {(msg) => <Typography color="error">{msg}</Typography>}
                                                </ErrorMessage>
                                             </Grid>
                                             <Grid item xs={6}>
                                                <Typography sx={{ color: "#585757" }} marginBottom={1}>
                                                   Correo electrónico alternativo
                                                </Typography>
                                                <Field
                                                   as={TextField}
                                                   fullWidth
                                                   type="email2"
                                                   size="small"
                                                   name="email2"
                                                   placeholder="correo@correo.com.mx"
                                                   value={values.email2}
                                                   onBlur={handleBlur}
                                                   onChange={handleChange}
                                                />
                                                <ErrorMessage name="email2">
                                                   {(msg) => <Typography color="error">{msg}</Typography>}
                                                </ErrorMessage>
                                             </Grid>
                                          </Grid>
                                       </Box>
                                    </>
                                 )}
                              </Stack>
                           </Stack>
                        </Box>
                        <Box
                           sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              bgcolor: "#F3F3F3",
                              width: "100%",
                              p: 1,
                              height: 50,
                              borderBottomRightRadius: 5,
                              borderBottomLeftRadius: 5,
                           }}
                        >
                           <Button
                              onClick={handleClose}
                              sx={{ color: "black", height: 35, ":hover": { bgcolor: "#E5E6EB" }, mr: 2 }}
                           >
                              Cancelar
                           </Button>
                           <Button
                              type="submit"
                              disabled={isLoadingSubmit}
                              sx={{ height: 35, ":hover": { bgcolor: "success" } }}
                              onClick={() => {
                                 console.log(values);
                              }}
                           >
                              {isLoadingSubmit ? <CircularProgress size={25} /> : "Guardar"}
                           </Button>
                        </Box>
                     </Form>
                  );
               }}
            </Formik>
         )}
      </CustomModalComponent>
   );
};

export const FileSelectedCollapse = ({ file, onClick }) => {
   const getImageUrl = async () => {
      const imagesUrlResponse = await getUrlS3("files-lecosy", { folder: file.fileDirection }, file.name);
      window.open(imagesUrlResponse, "_blank", "noopener,noreferrer");
   };

   return (
      <Collapse in={!!file}>
         {file && (
            <Stack
               direction={"row"}
               sx={{
                  alignItems: "center",
                  columnGap: 2,
                  justifyContent: "center",
               }}
            >
               <Button
                  sx={{ columnGap: 1 }}
                  onClick={() => {
                     file.fileDirection && getImageUrl();
                  }}
               >
                  {file.fileDirection && <OpenInNew />}

                  <Typography noWrap>{file.name}</Typography>
               </Button>
               <IconButton onClick={onClick}>
                  <Close color="error" />
               </IconButton>
            </Stack>
         )}
      </Collapse>
   );
};

export default ControllingBeneficiaryModal;
