import dayjs from "dayjs";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../userContext";
import { GovernanceContext } from "./governanceContext";
import { useParams } from "react-router-dom";
import { getChargeById } from "../../lib/gobCorpBEClient";
import { mainCharges } from "../../const/gobCopConst";
import { IGovernanceBody } from "../../types/governance.types";

interface IFilesContext {
   filesToDisplay: any[];
   setFilesToDisplay: Function;
   startDate: dayjs.Dayjs;
   endDate: dayjs.Dayjs;
   setStartDate: Function;
   setEndDate: Function;
   confirmModal: boolean;
   setConfirmModal: Function;
   permissions: Function;
   level: boolean;
   selectedFolder: { _id: string; title: string; company: string };
   setSelectedFolder: Function;
   selectedCompanyFolder: string;
   setSelectedCompanyFolder: Function;
   setDocumentSelected: Function;
   documentSelected: any;
   beneficiary: any;
   setBeneficiary: Function;
}

export const FilesContext = createContext<IFilesContext>({
   filesToDisplay: [],
   setFilesToDisplay: () => {},
   startDate: null,
   endDate: null,
   setStartDate: () => {},
   setEndDate: () => {},
   setConfirmModal: () => {},
   confirmModal: false,
   permissions: () => {},
   level: false,
   selectedFolder: { _id: "", title: "", company: "" },
   setSelectedFolder: () => {},
   selectedCompanyFolder: "",
   setSelectedCompanyFolder: () => {},
   setDocumentSelected: () => {},
   documentSelected: null,
   setBeneficiary: () => {},
   beneficiary: null,
});

export const FilesProvider = ({ children }) => {
   const [filesToDisplay, setFilesToDisplay] = useState(null);
   const [startDate, setStartDate] = useState(null);
   const [endDate, setEndDate] = useState(null);
   const [selectedFolder, setSelectedFolder] = useState({ _id: "", title: "", company: "" });
   const [selectedCompanyFolder, setSelectedCompanyFolder] = useState("");
   const [confirmModal, setConfirmModal] = useState(false);
   const [documentSelected, setDocumentSelected] = useState(null);
   const [beneficiary, setBeneficiary] = useState(null);
   const { user, isLoadingUser } = useContext(UserContext);
   const { gobernanceBody } = useContext(GovernanceContext);
   const [level, setLevel] = useState(false);
   const { id } = useParams();

   useEffect(() => {
      const fetchCharge = async () => {
         if (user?.role?.some((role) => role?.includes("Coordinador") || role?.includes("Usuario"))) {
            setLevel(true);
            return;
         }

         if (!id || gobernanceBody.length === 0) return;

         const governance = gobernanceBody.find((g) => g._id === id);
         if (!governance || !governance.users) return;

         const userEntry = governance.users.find((entry) => entry.user === user.id);
         if (userEntry) {
            const response = await getChargeById(userEntry.charge);
            if (mainCharges.includes(response.chargeName)) {
               setLevel(true);
            }
         }
      };

      fetchCharge();
   }, [id, gobernanceBody, user]);

   const permissions = useCallback(
      (file) => {
         if (file) {
            if (file.ownerId === user.id) return 3;
            if (level) return 3;
            const sharedAccess = file?.shared?.find((fileShare) => fileShare.user === user.id);
            if (sharedAccess) return sharedAccess.permission;
            return 0;
         }
      },
      [isLoadingUser, level]
   );

   return (
      <FilesContext.Provider
         value={{
            filesToDisplay,
            setFilesToDisplay,
            startDate,
            endDate,
            setStartDate,
            setEndDate,
            confirmModal,
            setConfirmModal,
            permissions,
            level,
            selectedFolder,
            setSelectedFolder,
            selectedCompanyFolder,
            setSelectedCompanyFolder,
            documentSelected,
            setDocumentSelected,
            setBeneficiary,
            beneficiary,
         }}
      >
         {children}
      </FilesContext.Provider>
   );
};
